<!-- eslint-disable tailwindcss/no-custom-classname -->
<template>
  <UiBaseTable
    class="m2-related-products-table"
    :headers="props.tableHeaders"
    :body="props.tableRows"
    :row-class="rowClasses"
    :row-cell-class="rowCellClasses"
    :styles="tableStyles"
    v-bind="$attrs"
  >
    <!-- Contains product's image, name, codes etc. -->
    <template #rowCell-product="{ row, rowCellClass }">
      <td
        class="text-start"
        :class="[rowCellClass]"
      >
        <div class="flex items-center gap-x-5">
          <UiProductDetails
            :name="row.item?.name ?? ''"
            :image="{ src: (row?.item?.thumbnail?.url ?? '') || (row?.item?.image?.url ?? ''), width: 72, height: 76 }"
            :brand="{
              src: getCustomAttributes(row.item)?.brand?.image ?? '',
              alt: getCustomAttributes(row.item)?.brand?.label ?? '',
              height: 20,
            }"
            :barcode="row.item?.barcode"
            :sku="row.item?.sku"
            :link="productLink(row.item?.canonical_url)"
            :user-code="getProductAlias(row.item) ?? ''"
            :style="{ minWidth: '400px' }"
            :enable-show-item-image-on-click="props.enableShowItemImageOnClick"
          >
            <template #hr>
              <hr
                class="my-2.5"
                :class="[
                  {
                    'border-blue-450':
                      props.variant === TableVariantEnum.UPSELL,
                    'border-yellow': props.variant === TableVariantEnum.RELATED,
                  },
                ]"
              >
            </template>
          </UiProductDetails>
        </div>
      </td>
    </template>

    <!-- Information about product's packing type -->
    <template #rowCell-packingType="{ row, rowCellClass }">
      <td :class="rowCellClass">
        <UiProductItemPackaging
          :image="getCustomAttributes(row.item)?.packagingImage?.image ?? ''"
          :description="getCustomAttributes(row.item)?.packagingTypeAndQuantities?.label ?? ''"
        />
      </td>
    </template>

    <!-- Controls to change item's quantity and information about product availablity -->
    <template #rowCell-amountOfPackages="{ row, rowIndex, rowCellClass }">
      <td :class="rowCellClass">
        <slot
          name="quantity"
          v-bind="{ item: row.item, labels, index: rowIndex }"
        >
          <UiQuantityBox
            v-if="getProductQuantityData(row.item)?.qty != null"
            :ref="(el) => setQuantityBoxElement(el, rowIndex)"
            :model-value="getProductQuantityData(row.item)?.min_sale_qty"
            :min="getProductQuantityData(row.item)?.min_sale_qty"
            :max="getProductQuantityData(row.item)?.max_sale_qty"
            :step="getProductQuantityData(row.item)?.qty_increments"
            :labels="labels.quantityBox"
            enable-info
            class="!w-16 justify-end !text-sm !font-medium text-primary md:justify-center"
            @change="changeRowItemQuantity($event, row.item, rowIndex)"
          />

          <div
            v-if="getProductQuantityData(row.item)?.qty != null && row.item.product_typename !== ProductType.VIRTUAL"
            class="mt-2.5 border-t pt-2.5"
            :class="[
              {
                'border-blue-450': props.variant === TableVariantEnum.UPSELL,
                'border-yellow': props.variant === TableVariantEnum.RELATED,
              },
            ]"
          >
            <M2ProductItemAvailabilityStockInfo
              v-if="Boolean(getProductQuantityData(row.item))"
              :available-quantity="getProductQuantityData(row.item)?.qty ?? 0"
              :measurement-unit="row.item?.sales_um"
            />
          </div>
        </slot>
      </td>
    </template>

    <!-- Net price for single item  -->
    <template #rowCell-netPriceForPackage="{ row, rowCellClass }">
      <td :class="rowCellClass">
        <M2ProductItemNetPriceForPackage
          :regular-price="getProductRegularPrice(row.item)"
          :individual-customer-single-package-price="
            getProductFinalPrice(row.item)
          "
          :final-price="getProductFinalPrice(row.item)"
        />
      </td>
    </template>

    <!-- Available and unavailable number of items with estimate delivery time -->
    <template #rowCell-deliveryTime="{ row, rowCellClass }">
      <td
        v-if="Boolean(getProductQuantityData(row.item))"
        class="min-w-[19ch]"
        :class="[rowCellClass]"
      >
        <M2ProductDeliveryTime
          :product-type="row.item?.product_typename"
          :quantity="getProductQuantityData(row.item)?.qty ?? 0"
          :availability-label="getProductQuantityData(row.item)?.availability_label"
          :measurement-unit="row.item?.sales_um"
        />
      </td>
    </template>

    <template #rowCell-addToCart="{ row, rowCellClass }">
      <td :class="rowCellClass">
        <M2AddToCartButton
          variant="icon"
          slim
          :disabled="shouldItemBeDisabled({ product: row.item, quantity: selectedQuantities?.[row?.item?.sku] ?? 1 })"
          @click:add-to-cart="handleAddToCart({ product: row.item, quantity: selectedQuantities?.[row?.item?.sku] ?? 1 })"
        >
          <slot name="addToCartIcon">
            <span
              class="flex items-center gap-1.5 rounded-3xl border border-primary py-2 pl-3.5 pr-5 group-hover:bg-blue-450"
            >
              <UiIcon
                class="text-blue-150"
                name="add-to-cart-plus-sign-outside"
                :width="20"
                :height="20"
              />

              <span class="whitespace-nowrap text-xs font-medium text-primary">{{
                t('ecom_to_cart')
              }}</span>
            </span>
          </slot>
        </M2AddToCartButton>
      </td>
    </template>

    <template #footer="slotData">
      <slot
        name="footer"
        v-bind="slotData"
      />
    </template>

    <template
      v-for="(_, name) in $slots"
      #[name]="slotData"
    >
      <slot
        :name="name"
        v-bind="slotData"
      />
    </template>
  </UiBaseTable>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import UiProductDetails from '@ui/components/UiProductDetails/UiProductDetails.vue'
import UiQuantityBox from '@ui/components/UiQuantityBox/UiQuantityBox.vue'
import UiBaseTable from '@ui/components/UiBaseTable/UiBaseTable.vue'
import type { Column } from '@ui/components/UiBaseTable/UiBaseTable.types'
import { useAppNav } from '@base/components/AppNav/useAppNav'
import { useCart } from '@ecom/composables/checkout/useCart/useCart'
import { COMMON_CHECKOUT_TABLE_COLUMN_WIDTHS } from '@ecom/types/checkout.types'
import { useProductPrice } from '@ecom/composables/product/useProductPrice/useProductPrice'
import useProductQuantity from '@ecom/composables/product/useProductQuantity'
import useProductAlias from '@ecom/composables/product/useProductAlias'
import { ProductType } from '@ecom/types/product.types'
import type { M2CatalogProductsTable } from './M2CatalogProductsTable.types'
import { TableVariantEnum } from './M2CatalogProductsTable.types'
import type { AttributeOption, CustomAttribute, ProductInterface } from '#gql'
import type { Maybe } from '#gql/default'

const props = withDefaults(defineProps<M2CatalogProductsTable>(), {
  enableShowItemImageOnClick: true,
  refetchCart: true,
  tableHeaders: () => [],
  disabledRows: () => [],
})
const emit = defineEmits<{
  (e: 'change-quantity', item: any, index: number): void
}>()
const { t } = useI18n()
const { productLink } = useAppNav()
const { getProductRegularPrice, getProductFinalPrice } = useProductPrice()
const { addToCart, shouldItemBeDisabled } = useCart()

const quantityBoxElements = ref<null[] | InstanceType<typeof UiQuantityBox>[]>(
  [],
)
const selectedQuantities = ref<Record<string, number | null>>({})

// It is product, not cart item
function changeRowItemQuantity(quantity: number, product, rowIndex: number) {
  Object.assign(selectedQuantities.value, { [product.sku]: quantity })
  emit('change-quantity', { product, quantity }, rowIndex)
}

function rowClasses() {
  return [
    {
      'bg-yellow-150': props.variant === TableVariantEnum.RELATED,
      'bg-blue-550': props.variant === TableVariantEnum.UPSELL,
    },
  ]
}

function rowCellClasses(cell: Column) {
  return [
    {
      'min-w-fit': cell?.name === 'addToCart',
      'min-w-[588px] w-[588px]': cell?.name === 'product',
      ...COMMON_CHECKOUT_TABLE_COLUMN_WIDTHS(cell),
      'min-w-[24.6ch]': cell?.name === 'addToCart',
      'border-yellow': props.variant === TableVariantEnum.RELATED,
    },
  ]
}

function getProductQuantityData(product: ProductInterface) {
  if (!product?.sku) {
    return
  }
  return useProductQuantity(product).productQuantityData.value
}

function getProductAlias(product: ProductInterface) {
  if (!product?.sku) {
    return
  }
  return useProductAlias(product).value
}

function getCustomAttributes(product: ProductInterface) {
  if (!product) {
    return
  }

  const { findRawlplugAttributes } = useRawlplugCustomAttributes(
    product.rawlplug_attributes as CustomAttribute[],
  )
  const brand = findRawlplugAttributes('brand')?.find(
    (item: Maybe<AttributeOption>) => item?.value === product.brand,
  )

  const packagingImage = findRawlplugAttributes(
    'ecommerce_packing_type',
  )?.find((item: any) => item.value)

  const packagingTypeAndQuantities = findRawlplugAttributes(
    'erp_packaging_and_quantity',
  )?.find((item: any) => {
    return item.value
  })

  return {
    brand,
    packagingImage,
    packagingTypeAndQuantities,
  }
}

async function handleAddToCart({ product, quantity }: { product: ProductInterface, quantity: number }) {
  if (!product.uid || !product.sku) {
    return
  }

  await addToCart({ product, quantity, refetchCart: props.refetchCart })
}

function setQuantityBoxElement(el: Element | ComponentPublicInstance | null, index: number) {
  if (el !== null) {
    quantityBoxElements.value[index] = el as InstanceType<typeof UiQuantityBox>
  }
}

const tableStyles = computed(() => {
  return {
    table: {
      el: 'relative min-w-full leading-normal',
    },
    thead: {
      el: 'border border-blue-100 text-xs text-primary',
      tr: '',
      th: 'px-3.5 py-4 bg-blue-500 border-r border-blue-100 last:border-r-0',
    },
    tbody: {
      el: '',
      tr: '',
      td: 'border h-full border-blue-100 px-3.5 py-4 text-xs text-center font-medium',
    },
    tfoot: {
      el: 'relative h-full',
      tr: 'border border-yellow',
      td: '',
    },
  }
})

const labels = computed(() => ({
  image: t('image'),
  name: t('name'),
  amountOfPackages: t('amount_of_packages'),
  yourNetPriceForPackage: t('your_net_price_for_package'),
  remove: t('delete'),
  yourProductCode: t('your_product_code'),
  packageAbbreviation: t('ecom_pck'),
  quantityBox: {
    min: t('ecom_min'),
    interval: t('ecom_interval'),
  },
}))

onBeforeMount(() => {
  // assign initial values for quantity inputs in table
  if (props.tableRows?.length) {
    props.tableRows.forEach((row) => {
      if (!row?.item?.sku) {
        return
      }
      Object.assign(selectedQuantities.value, {
        [row.item.sku]: getProductQuantityData(row.item)?.min_sale_qty,
      })
    })
  }
})

onBeforeUpdate(() => {
  quantityBoxElements.value = []
})

onUnmounted(() => {
  selectedQuantities.value = {}
})

defineExpose({
  quantityBoxElements,
  selectedQuantities,
})
</script>

<style lang="postcss" scoped>
[data-el='rowHeader']:first-child td {
  @apply border-t-blue-100 border-t-2;
}
</style>
