import type { Column, Row } from '@ui/components/UiBaseTable/UiBaseTable.types'

export enum TableVariantEnum {
  UPSELL = 'upsellProducts',
  RELATED = 'relatedProducts',
}

export type TableVariant = `${TableVariantEnum}`

export interface M2CatalogProductsTable {
  tableHeaders?: Column[]
  tableRows: Row[]
  disabledRows?: readonly string[]
  enableSelectedRowsRemove?: boolean
  enableShowItemImageOnClick?: boolean
  variant?: TableVariant
  refetchCart?: boolean
}
